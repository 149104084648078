.articles-list-wrapper {
    width: 100%;

    .articles-list {
        display: flex;
        width: 100%;

        .article-title {
            font-weight: 400;
            font-size: 14px;
        }

        .article-item {
            margin: 0px 2px;
            position: relative;

            .action-buttons {
                display: none;
                position: absolute;
                right: 0;
                bottom: 0;
                z-index: 10;
            }

            &:hover {
                .action-buttons {
                    display: flex;
                }
            }
        }

        .articles-left {
            flex-grow: 1;
            width: 50%;
            height: 300px;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                position: absolute;
                object-fit: cover;
            }

            .article-details {
                color: white;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                position: relative;

                .title {
                    font-weight: 400 !important;
                    font-size: 16px;
                }
                        .description {
                            font-size:12px;
                            font-style: italic;
                        }

                .date {
                    background-color: var(--primary-blue);
                    padding: 8px;
                    align-self: flex-start;
                }
            }
        }

        .articles-right {
            flex-grow: 1;
            width: 50%;
            display: flex;
            flex-direction: column;
            position: relative;
            max-height: 300px;

            img {
                width: 50%;
                height: 100%;
                object-fit: cover;
            }

            .article-item {
                .article-details {
                    display: flex;
                    max-height: 150px;

                    .image-article-visu {
                        height: 150px;
                        width: 225px;
                        background-position: center;
                        background-size: cover;
                        position: relative;
                        z-index: 0;
                    }

                    .article-content {
                        flex: 1;
                        position: relative;
                        z-index: 1;
                        overflow: hidden;

                        .title {
                            font-weight: 400 !important;
                            font-size: 16px;
                        }
                        .description {
                            font-size:12px;
                            font-style: italic;
                        }

                    }

                }

                display: flex;

                &:hover {
                    background-color: #EDF3FB;
                }

                .article-content {
                    flex-direction: column;
                    padding: 0 16px 16px 16px;

                    .title {
                        margin: 16px 0px 8px 0px;
                    }
                }
            }
        }

        .article-details {
            height: 100%;
            position: relative;
            cursor: pointer;
        }
    }
}


.article-details-wrapper {
    background-color: white;
    width: 100%;

    .article-content {
        display: flex;
        flex-direction: column;
        padding: 0px 28px;

        .article-img {
            height: 300px;
            width: 100%;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .article-card {
            transform: translate(0px, -54px);
            background-color: white;
            width: 90%;
            position: relative;
            margin: auto;
            padding: 32px;
        }
    }
}