.steps-list-wrapper {
    .title-desc-step {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        h1 {
            flex: 1;
            font-weight: 400;
        }
        p {
            flex: 2;
            font-weight: 400;
            padding-right: 64px;
        }
    }
    .steps-list {
        display: flex;
        flex-wrap: wrap;
        .a-phase {
            padding:16px;
            .phase-name {
                font-size:24px;
            }
            .phase-desc {
                white-space: pre-wrap;
                white-space: -moz-pre-wrap;
                white-space: -pre-wrap;
                white-space: -o-pre-wrap;
                word-wrap: break-word;
                padding-top:8px;
                font-size:14px;
            }
        }
        .step-card-wrapper {
            flex: 1;
            margin: 0.5%;
            display: flex;
            flex-direction: column;
            color: white;
            cursor: pointer;
            position: relative;
            padding-bottom: 32px;
            &:hover {
                box-shadow: 0 6px 6px -3px rgb(0 0 0 / 20%), 0 10px 14px 1px rgb(0 0 0 / 14%),
                    0 4px 18px 3px rgb(0 0 0 / 12%);
                .action-buttons {
                    display: flex;
                }
            }
            .step-card-title {
                font-weight: bold;
                font-size:16px;
            }

            .step-card-description {
                font-size:14px;
            }

            .step-card-title,
            .step-card-description {
                white-space: pre-wrap;
                white-space: -moz-pre-wrap;
                white-space: -pre-wrap;
                white-space: -o-pre-wrap;
                word-wrap: break-word;
                padding: 16px;
                margin: 0px;
            }
            .action-buttons {
                display: none;
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
        &.grouped-steps {
            display: flex;
            margin: 4px 0.5%;
            color: white;
            .steps-card {
                flex: 6;
                display: flex;
                .step-card-wrapper {
                    flex: 1;
                    margin: 0.5%;
                }
            }
            div:last-child {
                flex: 4;
                cursor: pointer;
                position: relative;
                .relative-open-form-btn {
                    opacity: 0;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }
                &:hover {
                    .relative-open-form-btn {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
.drop-area-add-button {
    button {
        background-color: #e6ac3e;
        border-color: transparent !important;
        &:active,
        &:hover {
            background-color: #8f6b29 !important;
        }
    }
}
.step-index {
    .admin_actions {
        display: flex;
        padding: 16px 0px;
        justify-content: left;

        .in-dom {
            position: relative;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }
    .workspace-banner {
        background-position: center;
        background-size: cover;
        background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("./../assets/head.png");
    }
    .right-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .steps-column-wrapper {
        padding-left: 48px;
        color: white;
        .steps-column {
            text-align: end;
            h4 {
                margin: 8px;
                text-align: left;
            }
            .home {
                background-color: var(--primary-blue);
            }
            .step-row-wrapper {
                display: flex;
                align-items: center;
                margin-top: 4px;
                margin-bottom: 4px;
                margin-right: 0;
                color: white;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
                &.selected {
                    background-color: white;
                    margin-left: -24px;
                }
                div {
                    margin-left: 8px;
                }
            }
        }
    }
}
.step-card-code {
    background-color: rgba(0, 0, 0, 0.2);
    padding: 8px 16px;
    align-self: flex-start;
}
