.navbar-overmind {
    flex:0;
    display: flex;
    background-color: var(--navbar-color);
    color:var(--navbar-color-text);
    flex-direction: row;
    width:100%;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 5px #191821DB;
    z-index:200;
    position:relative;
    .left-part {
        width:300px;
        img {
            height:30px;
        }
    }
    .middle-part {
        display: flex;
        align-items: center;
        justify-content: center;
        .p-button {
            font-size:18px;
            color:#ddd;
            font-weight: 100;
            position:relative;
            height: 63px;
            border-radius: 0;
            border-bottom: 4px solid transparent;
            &.active {
                border-bottom: 4px solid var(--primary-color);
            }
        }
    }
    .right-part {
        width:300px;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: right;
        padding-right: var(--content-padding);
        .p-button-link {
            color:white !important;
        }
        .p-avatar {
            margin-left: var(--content-padding);
            .p-avatar-icon {
                color:#222 !important;
            }
        }
        .hi-message {
            font-size:12px;
        }
    }
}
