.layout-two-columns {
    flex:1;
    display:flex;
    flex-direction: row;
    div:first-child {
        flex:2;
    }
    div:last-child {
        flex:1;
    }
}