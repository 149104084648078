.open-form-btn {
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 20;
}


.relative-open-form-btn {
    .open-form-btn {
        position: relative;
        right: unset;
        top: unset;
    }
}

.action-buttons button {
    background-color: rgba(160,160,160,0.8);
    border-radius: 0;
    border: none;
    margin-right: 2px;
    color: var(--primary-color);
}

.link-item {
    padding: 5%;
    color: white;
    background-color: red;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 33%;
    font-weight: 200;
    cursor: pointer;
    font-size: 0.8em;
    &:not(:first-child){
        margin-left: 4px;
    }
    i {
        font-size: 2em;
    }
}

.log-item {
    background-color: #E5EEF9;
    margin: 4px 0px;
    padding: 8px 8px;
    .title {
        font-weight: 400;
        font-size:12px;
    }
    .date {
        color: #333;
        font-size:12px;
    }
}

.tag-wrapper {
    position: absolute;
    top:0px;
    right: 0px;
    & > div {
        margin-bottom:4px;
    }
}

.workspace_name {
    color: #fff;
}