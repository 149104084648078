.layout-overmind{
    background-color:#252A48;
    border:0;
    margin:0;
    padding:0;
    flex:1;
    display:flex;
    position:relative;
    z-index:100;
    .sidebar {
        background-color:#5F00EF;
        flex:1;
        position: relative;
        padding-top: 1rem;
        .project {
            margin:10px 0px 0px 20px;
            color: white;
            display: flex;
            align-items: center;
            img {
                height: 50px;
                margin-right:5px;
            }
            .legend {
                color:#ddd;
                font-size:14px;
            }
            .project-name {
                font-weight: bold;
            }
        }
        .menu {
            img {
                filter: hue-rotate( 158deg);
                height: 22px;
                margin-right:24px;
            }
            .p-button {
                color:white !important;
                margin: 10px 16px;
                width: calc(100% - 32px);
                border-radius: 10px;
                padding: 18px;
                &.active {
                    background-color:#430AC9;
                }
                &.disabled {
                    color:#aaa !important;
                }
            }
            font-weight: bold;
        }
        .download {
            position:absolute;
            top:calc(100vh - 130px);
            left:0px;
            width:100%;
            font-size: 16px;
            display:flex;
            justify-content: center;
            padding:0 24px;
            button {
                color:var(--secondary-color-text) !important;
                width: 100%;
                border-radius: 32px;;
                text-align: center;
                justify-content: center;
            }
            svg {
                color:var(--secondary-color-text) !important;
                font-size:32px;
                margin-right:8px;
            }
        }
    }
    .main {
        flex:5;
    }
}


.terminal-demo p {
    margin-top: 0;
    font-size:14px;
}
.terminal-demo .p-terminal {
    width: 100%;
    background-color: #212121;
    color: #fff;
}
.terminal-demo .p-terminal .p-terminal-command {
    color: #80cbc4;
}
.terminal-demo .p-terminal .p-terminal-prompt {
    color: #ffd54f;
}
.terminal-demo .p-terminal .p-terminal-response {
    color: #9fa8da;
}