.user-table-header{
    background: white;
    width: 100%;
    margin-bottom:8px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 4px 4px 4px 16px;
    font-weight: bold;
    color:var(--primary-color)
}
.users-column-wrapper {
    padding-right:64px;
    .user-item {
        background-color: #5C84B3;
        margin: 2px 0px;
        display: flex;
        align-items: center;
        color: #E5EEF9;
        cursor: pointer;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        transition: border-top-left-radius 0.2s ease-in-out, border-bottom-left-radius 0.2s ease-in-out;
        font-weight: 200;
        position:relative;
        &:hover {
            .phone, .mail{
                opacity:1;
            }
        }
        .mail{
            position:absolute;
            width: 48px;
            height: 100%;
            background-color: white;
            right: -100px;
            top:0;
            transition:opacity 400ms;
            opacity:0.2;
            color: var(--primary-color);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
        }
        .phone {
            position:absolute;
            width: 48px;
            height: 100%;
            background-color: white;
            transition:opacity 400ms;
            right: -50px;
            top:0;
            opacity:0.2;
            color: var(--primary-color);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
        }
        &:hover {
            background-color: #00234E;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
        }
        .avatar-wrapper {
            padding: 4px;
            color: black;
        }
        .avatar-info {
            display: flex;
            width: 100%;
            & > div:first-child {
                flex:1;
                font-weight: 100;
            }
            & > div:last-child {
                padding-right: 0.5rem;
                flex:1;
                font-weight: 300;
            }
        }
    }
}

.p-tabview .p-tabview-nav{ 
    background: #f7f7f7 !important;
    li .p-tabview-nav-link {
    background: #f7f7f7 !important;
    color: #003D84 !important;
    border: none !important;
    font-weight: normal !important
}
 li.p-highlight .p-tabview-nav-link {
    font-weight: 600 !important;
    border: solid #003D84 !important;
    border-width: 0 0 4px 0 !important;
}
}

.p-tabview .p-tabview-panels{
    background: #f8f8f8 !important;
}

.p-datatable .p-datatable-thead > tr > th {
    text-align: center !important;
    padding: var(--content-padding) var(--content-padding);
    border: 1px solid var(--surface-d);
    border-width: 0 0 1px 0;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #7E7E7E !important;
    font-family: "Lato";
    background: #f4f4f4 !important;
    transition: box-shadow 0.2s;
}

.p-datatable .p-datatable-tbody > tr > td {
    padding: 0.8rem !important;
    color: #95989B;
    font-size: 15px;
}

.action-list {
    .p-button{
        padding: 0.4rem 0.8rem !important;
    }
    .p-button:not(:first-child){
        margin-left: 0.3rem;
    }
}

.p-tabview .p-tabview-panels{
    padding: 0 1rem 0.5rem 1rem !important;
}

.p-avatar.p-avatar-lg {
    width: 2rem !important;
    height: 2rem !important;
    font-size: 1rem !important;
}

