.interns-list-wrapper {
    .p-datatable-tbody {
        tr {
            .action-buttons{
                visibility: hidden;
                right: 0;
                bottom: 0;
            }
            &:hover {
                background-color: var(--primary-color);
                color: white;
                .action-buttons {
                    visibility: visible;
                }
            }
        }
    }
}