.document-form {
    padding: 2rem 0 0 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
}

.documents-list {
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;

    &>.open-form-btn {
        top: -20px;
        right: -0px;
    }

    .edit-icon {
        .open-form-btn {
            right: 16px !important;
            top: 40px !important;
        }
    }

    .documents-tabs {
        display: flex;
        flex-wrap: wrap;

        .tab {

            &.active,
            &:hover {
                .name {
                    font-weight: bold;
                    border-bottom: 2px solid #003d84;
                    background-color: #d9e1f6;
                }
            }

            display: flex;
            align-items: center;
            justify-content: center;

            .name {
                flex-grow: 1;
                color: #003d84;
                font-size: 14px;
                margin-right: 8px;
                cursor: pointer;
                padding: 4px 24px;
                background-color: #e8eaf1;
                border-radius: 16px 16px 0 0;
                border-bottom: 2px solid #e8eaf1;
            }

            .cross {
                display: block;
                cursor: pointer;
                font-size: 12px;
            }

            margin-right: 8px;
        }
    }

    .drag-and-drop {
        width: 100%;
        height: 200px;
        background-color: #f2f6fc;
        border-radius: 0px 10px 10px 10px;
        font-size: 16px;
        line-height: 19px;
        padding: 17px 31px;
        color: #181c1f;
        display: flex;
        flex-direction: column;
        position: relative;

        .name {
            font-weight: bold;
            margin-bottom: 18px;
            flex: 0;
        }

        .description {
            flex: 0;
            margin-bottom: 8px;
        }

        .drop-area {
            position: relative;
            width: 100%;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            .dropZone {
                z-index: 1;
                position: absolute;
                width: 410px;
                height: 88px;

                &:hover {
                    cursor: pointer;
                }

                &:hover+div {
                    background: #d9e1f6;
                }
            }

            .zone {
                z-index: 0;
                overflow: hidden;

                &:hover,
                &.dragging {
                    background: #d9e1f6;
                    cursor: pointer;
                }

                width: 410px;
                height: 88px;
                background: #f2f6fc;
                border: 0.5px dashed #003d84;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-weight: 200;
                position: relative;

                input {
                    position: absolute;
                    top: 100px;
                }

                .drop-icon img {
                    width: 50px;
                    height: 50px;
                }

                .drop-text {
                    strong {
                        display: inline-block;
                        cursor: pointer;
                        font-weight: 200;
                        color: #003d84;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}


.document-info {
    display: flex;
    flex-direction: column;
    padding: 8px;
}

.document-list {
    .actions {
        .download {
            margin-right: 16px;
        }

        button {
            height: 40px;
        }

        display: flex;
        justify-content: flex-end;

        .in-dom {
            top: 0;
            right: 0;
            left: 0;
            right: 0;
            position: relative;
        }
    }
    .p-datatable-tbody {
        tr td {
            color: #003d84;
        }
    }
}

.drop-doc-area {
    .title {
        color: #e5ac3e;
    }

    button {
        background-color: #e5ac3e;
        border-color: transparent !important;

        &:active,
        &:hover {
            background-color: #7f6b29 !important;
        }
    }
}



.documents-list-wrapper.drop-area {

    .title,
    .tab {
        color: #e6ac3e;

        .name {
            color: #e6ac3e;
        }

        &.active,
        &:hover {
            .name {
                font-weight: bold;
                border-bottom: 2px solid #e6ac3e !important;
            }
        }
    }

    .drop-area {
        filter: hue-rotate(180deg);
    }

    button {
        background-color: #e6ac3e;
        border-color: transparent !important;

        &:active,
        &:hover {
            background-color: #8f6b29 !important;
        }
    }

    .p-datatable-tbody {
        tr td {
            color: black;
        }
    }
}