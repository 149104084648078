.note-item {
    background-color: #F2F6FC;
    padding: 16px;
    margin: 8px 0px;
    position: relative;
    .note-title {
        font-weight: bold;
    }
    .action-buttons{
        display: none;
        position: absolute;
        right: 0;
        bottom: 0;
    }
    &:hover {
        background-color: #EDF3FB;
        .action-buttons {
            display: flex;
        }
    }
}

.add-note-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dotted var(--primary-blue);
    padding: 32px;
    cursor: pointer;
    &:hover {
        background-color: #F2F6FC;
    }
}