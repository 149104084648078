.events-list {
    display: flex;
    flex-wrap: wrap;
    .description pre {
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
        width: 100%;
    }

    .event-item {
        padding: 32px;
        cursor: pointer;
        margin: 0px 0.5%;
        width: 19%;

        .date {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 8px 16px;
            border: 1px solid var(--primary-blue);
            margin-bottom: 8px;
            font-size: 1.6em;
            color: var(--primary-blue);
        }

        .title {
            margin-bottom: 8px;
        }

        .action-buttons {
            display: none;
            position: absolute;
            right: 0;
            bottom: 0;
        }

        &:hover {
            background-color: var(--primary-blue);
            color: white;

            .action-buttons {
                display: flex;
            }

            .date {
                border: 1px solid white;
                color: white;
            }

            .title {
                color: white;
            }
        }
    }
}