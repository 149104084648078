.layout-mainpage{
    background-color:#252A48;
    border:0;
    margin:0;
    padding:0;
    flex:1;
    display:flex;
    position:relative;
    z-index:100;
    .mainpage-root {
        display: flex;
        flex: 1;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
    }

    .data-table-spinner {
        margin-top: 50%;
        transform: translateY(-50%);
    }
    .data-table {
        margin:29px 25px;
        background-color: var(--surface-a);
        tr:last-child {
            td {
                border-width: 0 0 0px 0 !important;
            }
        }
        tr:nth-child(even) {
            background: #fafafa!important;
        }
        .action-list {
            display: flex;
            flex-direction: row;
            > div:not(:last-child) {
                margin-right:16px;
            }
        }
    }

    .p-datatable {
        width: 100%;
    }
}