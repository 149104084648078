.workspace {
    background-color: white;
    width: 100%;
    height: 100%;
    display: flex;
    .button-show-full-calendar {
        margin-top: 16px;
        position: relative;
        padding-right: 64px;
        .annuaire {
            font-weight: 200;
            padding: 16px;
        }
        .add {
            position: absolute;
            right: 0px;
            width: 56px !important;
            padding: 16px;
        }
    }
    .admin_actions {
        display: flex;
        padding: 16px 0px;
        justify-content:left;

        .in-dom {
            position:relative;
            left:0;
            right:0;
            top:0;
            bottom:0;
        }
    }
    .workspace-banner {
        position: relative;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .desc {
            min-height: 180px;
            font-weight: 200;
            position: absolute;
            bottom: 0px;
            left: 32px;
            color: white;
            padding: 16px 32px;
            width: calc(100% - 32px);
            .blocky {
                content: "";
                position: absolute;
                width: 32px;
                height: 110px;
                background-color: var(--primary-blue);
                left: -32px;
                top: 20px;
                .number {
                    width: 100%;
                    text-align: center;
                    height: 32px;
                    line-height: 32px;
                    font-size: 18px;
                    background: rgba(255, 255, 255, 0.5);
                }
            }
            h1 {
                margin: 0;
                font-weight: 400;
            }
            p {
                line-height: 24px;
                font-weight: 100;
            }
        }
    }
    .workspace-banner-img {
        background-position: center;
        background-size: cover;
        background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("./../assets/head.png");
    }
    .left-content {
        padding:0;
        width: 75%;
        height: 100%;
        overflow-y: auto;
        .workspace-banner {
            width: 100%;
            height: 400px;
        }
    }
    .right-content {
        width: 25%;
        height: 100%;
        overflow-y: auto;
        background-color: var(--primary-blue);
        .p-button-outlined {
            color: white;
            width: 100%;
        }
        .p-button.p-button-outlined:not(a):not(.p-disabled):hover {
            color: white;
        }
    }
}

.grid-container {
    display: grid;
    grid-template-columns: 1.5fr 2fr;
    grid-gap: 5px;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        border-radius: 25px;
        padding: 0.5rem;
        object-fit: cover;
    }
}

.workspace-grid-card {
    cursor: pointer;
    width: 100%;
    height: 100%;
    transition: all 0.2s ease-in-out;
    background-color: white;
    transition: 200ms;
    transform: rotate(0deg);
    filter:brightness(1);
    opacity: 1;
    &.archived {
        opacity: 0.7;
        filter:brightness(0.8);
        transform: rotate(2deg);
        &::after {
            content:'archivé';
            position:absolute;
            bottom:10px;
            right:10px;
        }
    }
}

.ws-info {
    color: var(--primary-blue);
    font-size: smaller;
    .title {
        padding-bottom: 0.5em;
        padding-top: 0.5em;
        font-family: "Roboto";
        font-weight: bold;
        .menu {
            float: right;
            margin-right: 0.5em;
            background: var(--primary-blue);
            color: white;
            padding-left: 0.85rem;
            padding-right: 0.85rem;
            border-radius: 25px;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
        span {
            line-height: 2rem;
            font-size: 20px;
            font-weight: normal !important;
        }
    }
    .info {
        padding-bottom: 0.5rem;
        font-size: 15px;
        svg {
            margin-right: 1rem;
        }
    }
    .info-dull {
        opacity: 0.59;
        font-size: 11px;
    }
}

.ws-text-wrapper {
    padding-top: 1.5rem;
    padding-right: 0.2rem;
}

.workspace-title {
    color: var(--primary-blue) !important;
    font-family: "Roboto";
    margin-left: 0.8rem !important;
    font-size: large !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .filter-list {
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap:2rem;
        color: #333;
        font-weight: normal;
        .p-checkbox {
            margin-right: 12px;
        }
    }
}

.workspace-wrapper {
    .workspace-title {
        color: white;
        font-weight: bold;
        font-size: 24px;
        margin: 10px 0px 20px 0px;
    }
    .workspace-cards {
        margin: 1%;
        .workspace-card {
            background-color: #2c3256;
            width: 24%;
            border-radius: 24px;
            display: inline-flex;
            flex-direction: column;
            overflow: hidden;
            &:not(:last-child) {
                margin-right: 1%;
            }
            .illustration {
                flex: 3;
                background-color: white;
                position: relative;
                padding: 10px 0 8px 0;
                img {
                    width: 90%;
                    margin-left: 5%;
                }
                .action-list {
                    position: absolute;
                    top: 8px;
                    right: 8px;
                    display: flex;
                    flex-direction: row;
                    > div:not(:last-child) {
                        margin-right: 4px;
                    }
                    .p-button {
                        color: white;
                        border-width: 0;
                        background-color: #252a48 !important;
                    }
                }
            }
            .text {
                flex: 2;
                padding: 12px;
                color: white;
                position: relative;
                .title {
                    font-size: 20px;
                    font-weight: bold;
                }
                .usedBy {
                    position: absolute;
                    bottom: 0px;
                    right: 8px;
                    .p-avatar {
                        background-color: #252a48;
                        border-radius: 32px;
                        width: 2.2rem;
                        height: 2.2rem;
                        .p-avatar-text {
                            font-size: 1.2rem !important;
                        }
                    }
                }
            }
        }
        .createNew {
            position: absolute;
            bottom: 32px;
            right: 32px;
            color: #252a46;
        }
    }
}
