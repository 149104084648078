.login-form {
    height:100%;
    background-color: #252a48;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    color:white;
    .reset-password-form-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        width:450px;
        #password,
        #confirm_password {
            width:100%;
        }
        #confirm_password {
            margin-bottom:24px;
        }
        .welcome {
            color:white;
            font-size: 32px;
            margin: 8px;
        }
        .welcome-text {
            color:white;
            font-size: 12px;
            padding-bottom: 32px;
        }
        .p-field {
            width: 100%;
            .p-d-block {
                color:white;
                font-size: 12px;
                font-weight: bold;
                padding-left:16px;

            }
        }
        input {
            width: 100%;
        }
        button {
            border-width: 1px;
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
        }
    }
    .login-form-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        width:250px;

        .logo {
            margin-bottom: 40px;
            width: 200px;
        }
        .welcome {
            color:white;
            font-size: 32px;
            margin: 8px;
        }
        .welcome-text {
            color:white;
            font-size: 12px;
            padding-bottom: 32px;
        }
        .p-field {
            width: 100%;
            .p-d-block {
                color:white;
                font-size: 12px;
                font-weight: bold;
                padding-left:16px;

            }
        }
        input {
            width: 100%;
        }
        button {
            border-width: 1px;
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
        }
    }
}