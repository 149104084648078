.tasks-list {
    .task-item {
        display: flex;
        margin: 4px 0px;
        background-color: #F2F6FC;
        position: relative;
        .action-buttons{
            display: none;
            position: absolute;
            right: 0;
            bottom: 0;
        }
        &:hover {
            background-color: #EDF3FB;
            .action-buttons {
                display: flex;
            }
        }
        .task-content {
            padding: 16px;
            width: 100%;
        }
        .task-title {
            font-weight: bold;
        }
        .task-state {
            width: 6px;
            background-color: #DC143C; 
            &.task-state-inprogress {
                background-color:#FFA500 ;
            }
            &.task-state-done {
                background-color: #008000;
            }
        }
    }
}