@import "primeflex/src/variables.scss";
@import "primeflex/src/grid.scss";
@import "primeflex/src/formlayout.scss";
@import "primeflex/src/display.scss";
@import "primeflex/src/text.scss";
@import "primeflex/src/flexbox/flexbox.scss";
@import "primeflex/src/spacing.scss";
@import "primeflex/src/elevation.scss";
@import "primeicons/primeicons.css";
@import "primeflex/primeflex.css";

@import "./assets/fonts/roboto.css";
@import "primereact/resources/primereact.min.css";
@import "./theme.scss";

@import "./layouts/styles.scss";

:root {
    height: 100%;
    --primary-color: #003d84 !important;
    --primary-bg-color: #f1f1f1 !important;
    --primary-color-text: white !important;
    --secondary-color: #002f64 !important;
    --primary-blue: #003d84;
    --step-0-color: #b1b1b1;
    --step-1-color: #fdae19;
    --step-2-color: #1daeb0;
    --step-3-color: #8cd483;
    --step-3-bg-color: #669a5f;
    --step-4-color: #6578da;
    --step-4-bg-color: #4255b7;
    --step-5-color: #1daeb0;
    --step-5-bg-color: #148183;
    --navbar-color: #313131 !important;
}

html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    color: var(--text-color);
}

#root {
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.app-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #252a48;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}

#app-layout {
    height: 100%;
}

.main {
    background-color: var(--primary-bg-color);
}
.layout-mainpage {
    height: calc(100% - 63px);
}

.title {
    color: var(--primary-blue);
    font-weight: 400;
}

.navbar {
    background-color: #222222;
    flex: 1 1;
    font-weight: 200;
    max-height: 54px;
    strong {
        font-weight: 200 !important;
        color: white;
    }
}

.navbar-icon {
    margin-right: 8px;
}

.middle-part {
    .p-button {
        font-size: 0.9rem !important;
        color: white !important;
        font-weight: bold !important;
        position: relative !important;
        height: 63px !important;
        border-bottom: 4px solid transparent !important;
        &.active {
            border-bottom: 4px solid #610007 !important;
            background-color: #7777774a !important;
            box-shadow: none !important;
        }
        &.focus {
            border-bottom: 4px solid #610007 !important;
            background-color: #7777774a !important;
            box-shadow: none !important;
        }
    }
}

.login-form {
    background-image: url("./assets/construction.jpg");
    background-position: center;
    background-size: cover;
    label {
        font-size: 16px;
    }
}

.description {
    color: grey;
}

.p-grid,
.p-col-6 {
    margin: 0;
    padding: 0;
}

.back-arrow {
    position: absolute;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.p-avatar {
    border-radius: 50%;
}

/* Loader */
.loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .loader {
        border: 5px solid #f3f3f3;
        border-radius: 50%;
        border-top: 5px solid #3498db;
        width: 40px;
        height: 40px;
        -webkit-animation: spin 2s linear infinite; /* Safari */
        animation: spin 2s linear infinite;
    }
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
